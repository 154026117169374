import * as React from 'react';

import { useSsoQueryParams } from '/hooks/useSsoQueryParams';

import { useRouter } from 'next/router';
import { useSession } from '@clerk/nextjs';

import { useLogin } from '/hooks/useLogin';

export const useHandleSsoRedirection = () => {
  const router = useRouter();
  const sso = useSsoQueryParams();
  const { handleLoginSuccess } = useLogin();
  const { isSignedIn, session } = useSession();

  // Handle sso login if is already signed in and has sso params
  React.useEffect(() => {
    if (sso && isSignedIn && !router.query.__clerk_ticket) {
      session?.getToken().then((clerkToken) => {
        handleLoginSuccess({
          sso,
          clerkToken,
        });
      });
    }
  }, [handleLoginSuccess, isSignedIn, router, session, sso]);
};
