import { useTranslation } from 'next-i18next';
import * as React from 'react';
import { Box, Button } from 'ui';

import {
  OAuthFinaryProvider,
  useSocialConnection,
} from '/hooks/useSocialConnection';

type SocialConnectionProps = {
  authenticationFlow: 'signup' | 'login';
};

export const SocialConnection = ({
  authenticationFlow,
}: SocialConnectionProps) => {
  const { t } = useTranslation(['pages', 'common']);
  const { socialSignUpWith, socialSignInWith } = useSocialConnection();

  const isSignup = authenticationFlow === 'signup';

  const onSocialConnectionButtonPress = (provider: OAuthFinaryProvider) => {
    if (isSignup) {
      socialSignUpWith(provider);
    } else {
      socialSignInWith(provider);
    }
  };

  return (
    <Box gap="s3" flexDirection="column">
      <Button
        type="button"
        variant="blue"
        size="large"
        prefixIcon="google"
        onClick={() => onSocialConnectionButtonPress('google')}
        style={{ flex: 1 }}
      >
        {t('pages:signup.continue_with_google')}
      </Button>
      <Button
        type="button"
        variant="white"
        size="large"
        prefixIcon="apple"
        onClick={() => onSocialConnectionButtonPress('apple')}
        style={{ flex: 1 }}
      >
        {t('pages:signup.continue_with_apple')}
      </Button>
    </Box>
  );
};
