import { ClerkAPIResponseError } from '@clerk/shared';
import NextLink from 'next/link';
import { useTranslation } from 'next-i18next';

import { Interpolate } from '/components/common/Interpolate';
import { APP_ROUTE } from '/utils/url';

const SUPPORTED_CLERK_SIGNIN_ERROR_CODES = [
  'form_identifier_not_found',
  'form_password_incorrect',
  'verification_failed',
  'identifier_already_signed_in',
  'form_code_incorrect',
  'form_param_value_invalid',
  'resource_forbidden',
  'session_exists',
  'client_state_invalid',
  'too_many_requests',
  'resource_not_found',
  'no_second_factors',
  'sign_in_no_identification_for_user',
  'sign_in_identification_or_user_deleted',
  'strategy_for_user_invalid',
] as const;

export type SupportedClerkSigninErrorCodes =
  (typeof SUPPORTED_CLERK_SIGNIN_ERROR_CODES)[number];

export type ClerkErrorProps = {
  signInError: ClerkAPIResponseError;
};

export const ClerkError = ({ signInError }: ClerkErrorProps) => {
  const { t } = useTranslation(['errors']);
  const { code, longMessage, message } = signInError.errors?.[0] || {};

  if (
    !code ||
    !(SUPPORTED_CLERK_SIGNIN_ERROR_CODES as ReadonlyArray<string>).includes(
      code
    )
  ) {
    return longMessage ?? message ?? null;
  }

  if (code === 'form_password_incorrect') {
    return (
      <Interpolate
        ns="errors"
        i18nKey="clerk_sign_in.form_password_incorrect"
        components={{
          internalLink: (
            <NextLink
              style={{ textDecoration: 'underline' }}
              href={APP_ROUTE.forgot_password}
            />
          ),
        }}
      />
    );
  }

  return t(`errors:clerk_sign_in.${code as SupportedClerkSigninErrorCodes}`);
};
