import { useAuth } from '@clerk/nextjs';
import { useClearCache } from 'core-api';
import * as Sentry from '@sentry/nextjs';
import { useAtom } from 'jotai';
import { useFlagsmith } from 'flagsmith/react';

import { analytics } from '/services/analytics';
import { impersonatedClerkTicketAtom } from '/contexts/impersonate';
import { investLedgerIdAtom } from '/contexts/invest';

export const useLogout = () => {
  const [, setImpersonatedClerkTicket] = useAtom(impersonatedClerkTicketAtom);
  const [, setSelectedLedgerId] = useAtom(investLedgerIdAtom);
  const { signOut } = useAuth();
  const flagsmith = useFlagsmith();
  const clearCache = useClearCache();

  return async () => {
    setImpersonatedClerkTicket(undefined);
    setSelectedLedgerId(null);
    clearCache();
    await signOut();
    Sentry.setUser(null);
    window.Intercom?.('shutdown');
    await flagsmith.logout();
    await analytics.reset();
    // 🚨 Eveything called after analytics.reset wont be called
  };
};
