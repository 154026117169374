import { NextRouter, useRouter } from 'next/router';
import * as React from 'react';

// Not sure this is the idiomatic way to accept 2 different types
export const extractSSOQueryParams = (
  queryParams?: URLSearchParams,
  router?: NextRouter
) => {
  // This may be called either from a page or from a middleware so we need to handle
  // URLSearchParams or router query params depending on context
  let sso: string | string[] | null = null;
  let sig: string | string[] | null = null;

  if (queryParams) {
    sso = queryParams.get('sso');
    sig = queryParams.get('sig');
  } else if (router) {
    sso = router.query.sso || null;
    sig = router.query.sig || null;
  }

  return [sso, sig];
};

export const useSsoQueryParams = () => {
  const router = useRouter();

  const [sso, sig] = extractSSOQueryParams(undefined, router);

  return React.useMemo(() => {
    if (typeof sig === 'string' && typeof sso === 'string') {
      return {
        sso,
        sig,
      };
    }

    return undefined;
  }, [sig, sso]);
};
