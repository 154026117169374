import { ClerkAPIResponseError } from '@clerk/shared';
import { captureException } from '@sentry/nextjs';
import { SSO } from 'core-api';
import { useRouter } from 'next/router';

import { getAuthHeaders, startSSO } from '/utils/auth';
import {
  analytics,
  INTERNAL_ERROR,
  INVALID_PARAMETERS,
} from '/services/analytics';
import { APP_ROUTE } from '/utils/url';

import * as React from 'react';

export type HandleLoginSuccessProps = {
  clerkToken?: string | null;
  redirect?: string;
  sso?: SSO;
};

export const useLogin = () => {
  const router = useRouter();

  const handleLoginSuccess = React.useCallback(
    async ({
      sso,
      clerkToken,
      redirect = APP_ROUTE.dashboard(),
    }: HandleLoginSuccessProps = {}) => {
      if (sso && clerkToken) {
        try {
          const { sso_return_url } = await startSSO(
            sso,
            getAuthHeaders(clerkToken)
          );
          if (sso_return_url) {
            window.location.href = sso_return_url;
            return;
          }
        } catch (error) {
          captureException(error);
        }
        // Don't redirect when attempting SSO
        return;
      }
      analytics.track({ event: 'Web Login Success' });
      await router.replace(redirect);
    },
    [router]
  );

  const handleLoginError = React.useCallback(
    (error: ClerkAPIResponseError | Error) => {
      const sentryEventId = captureException(error);
      analytics.track({
        event: 'Web Login Failed',
        properties: {
          web_login_failed_error: (error as ClerkAPIResponseError).errors?.[0]
            ?.code
            ? INVALID_PARAMETERS
            : INTERNAL_ERROR,
          sentryEventId,
        },
      });
    },
    []
  );

  return {
    handleLoginSuccess,
    handleLoginError,
  };
};
